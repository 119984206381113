import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import packageJson from '../../package.json';
import Button from "@mui/material/Button";
import {uploadSiteToIpfs} from "../ipfs";
import * as store from "../store";
import {SERVER_HTTP, SERVER_WS} from "../server";

export default function EditorMenu({focusCallback}) {

    return <AppBar position="static"
                   onFocus={() => focusCallback(true)} onBlur={() => focusCallback(false)}>
        <Toolbar variant="dense" sx={{m: -0.7}}>
            {/*<IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}>*/}
            {/*    <MenuIcon/>*/}
            {/*</IconButton>*/}
            <Typography variant="h6" color="inherit" component="div" sx={{flexGrow: 1}}>
                MML Editor v{packageJson.version}
            </Typography>
            <ExportButton/>
            <OpenLiveObject/>
            <OpenPlayView/>
            {/*<AddButton createElementCallback={createElementCallback}/>*/}
            {/*<ConnectButton/>*/}
            {/*<CreatePanel sx={{ml: 2}}/>*/}
        </Toolbar>
    </AppBar>
}

function ExportButton() {
    const uploadSite = async () => {
        console.log("UPLOADING SITE");
        const result = await uploadSiteToIpfs();

        const hash = result.Hash;
        const name = result.Name;

        const url = "https://mml.infura-ipfs.io/ipfs/" + hash;
        window.open(url, null);
    };

    return <Button onClick={uploadSite} sx={{mr: 1, mb: 0.5, mt: 0.5}}>
        IFPS Export
    </Button>
}

function OpenLiveObject() {
    const uploadSite = async () => {
        window.open(`${SERVER_HTTP}/client/${store.getSceneName()}`, null);
    };

    return <Button onClick={uploadSite} sx={{mr: 1, mb: 0.5, mt: 0.5}}>
        Live View
    </Button>
}

function OpenPlayView() {
    const uploadSite = async () => {
        window.open(`https://mml-editor.spaceconfig.com/?scriptsV0=https://dom.metaweb.tools/index.js?url=${SERVER_WS}/objects/${store.getSceneName()}`, null);
    };

    return <Button onClick={uploadSite} sx={{mr: 1, mb: 0.5, mt: 0.5}}>
        Play View
    </Button>
}
