import Tooltip from "@mui/material/Tooltip";
import copy from "clipboard-copy";
import * as React from "react";

export function CopyToClipboard(props) {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const onCopy = (content) => {
    copy(content);
    setShowTooltip(true);
  };

  return (
    <Tooltip
      open={showTooltip}
      title={"Copied to clipboard!"}
      leaveDelay={1000}
      onClose={() => {
        setShowTooltip(false);
      }}
    >
      {props.children({ copy: onCopy })}
    </Tooltip>
  );
}
