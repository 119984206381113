import * as store from "./store";

export async function uploadFileToIpfs(file) {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Basic MkVQNVNFeEFhRUJ2UDVxbXJHOEJRMWtaZFBaOjAzOTkwODRhMzc0MjVkOWRiNzlmZTVmMDEwNmNiNTY5",
    );

    const buffer = await file.arrayBuffer();
    const formdata = new FormData();
    formdata.append("", new Blob([buffer]), file.name);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    return await fetch("https://ipfs.infura.io:5001/api/v0/add", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
}

export async function uploadBlobToIpfs(blob, fileName) {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Basic MkVQNVNFeEFhRUJ2UDVxbXJHOEJRMWtaZFBaOjAzOTkwODRhMzc0MjVkOWRiNzlmZTVmMDEwNmNiNTY5",
    );

    const formdata = new FormData();
    formdata.append("", blob, fileName);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    return await fetch("https://ipfs.infura.io:5001/api/v0/add", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
}

export async function uploadSiteToIpfs() {
    const basicSite =
        '<html><script src="https://mml-client.metaweb.tools/index.js"></script><body>' +
        store.getScene() +
        "</body></html>";

    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Basic MkVQNVNFeEFhRUJ2UDVxbXJHOEJRMWtaZFBaOjAzOTkwODRhMzc0MjVkOWRiNzlmZTVmMDEwNmNiNTY5",
    );

    const formdata = new FormData();
    formdata.append("", basicSite);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    return await fetch("https://ipfs.infura.io:5001/api/v0/add", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
}
