import Editor, {DiffEditor, useMonaco, loader} from "@monaco-editor/react";
import * as store from "../store";
import React, {useEffect} from "react";
import * as beautify from "js-beautify";

export default function HTMLEditor() {
    const scene = store.useScene();

    const monacoRef = React.useRef(null);

    const [code, setCode] = React.useState(beautify.html(scene.innerHTML));
    const [focus, setFocus] = React.useState(false);

    React.useEffect(() => {
        if(!focus) {
            setCode(beautify.html(scene.innerHTML));
        }
    });

    const handleEditorWillMount = (monaco) => {
        // here is the monaco instance
        // do something before editor is mounted

        // monaco.editor.onDidBlurEditorText(() => {
        //     console.log("blur");
        // });
    }

    const handleEditorDidMount = (editor, monaco) => {
        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        monacoRef.current = editor;
        editor.onDidFocusEditorWidget(() => {
            setFocus(true);
        });
        editor.onDidBlurEditorWidget(() => {
            setFocus(false);
        });
    }

    // useEffect(() => {
    //     setCode(scene.innerHTML);
    // }, [scene]);

    return <Editor
        height="90vh"
        theme={"vs-dark"}
        defaultLanguage="html"
        beforeMount={handleEditorWillMount}
        onMount={handleEditorDidMount}
        options={{wordWrap: "on"}}
        value={code}
        onChange={(e) => {
            scene.innerHTML = e;
            setCode(e);
        }}
    />
}