import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {uploadFileToIpfs, uploadSiteToIpfs} from "./ipfs";
import ElementPanel from "./components/ElementPanel";

import ScenePanel from "./components/ScenePanel";
import EditorMenu from "./components/EditorMenu";
import {GoldenLayout} from "golden-layout";

import * as store from "./store";
import {darkTheme} from "./theme";
import HTMLEditor from "./components/HTMLEditor";
import EditorSceneView from "./components/EditorSceneView";
import PlaySceneView from "./components/PlaySceneView";
import MetadataPanel from "./components/MetadataPanel";

var config = {
    content: [{
        type: 'row', content: [{
            type: 'column', width: 10, content: [{
                type: 'component',title: 'Scene', componentName: 'scene', componentState: {label: 'B'}
            },
                {
                    type: 'component', title: 'Metadata', componentName: 'metadata', componentState: {label: 'B'}
                }
            ]
        }, {
            type: 'column', content: [{
                type: 'stack', height: 80, content: [{
                    type: 'component', componentName: 'edit', title: 'Edit', componentState: {label: 'A'}
                }, {
                    type: 'component', componentName: 'play', title: 'Play', componentState: {label: 'A'}
                }],
            }, {
                type: 'stack', content: [{
                    type: 'component', componentName: 'mml', title: 'MML', componentState: {label: 'D'}
                }],
            }]
        }, {
            type: 'component', width: 10, title: 'Inspector', componentName: 'inspector', componentState: {label: 'C'}
        }]
    }], dimensions: {
        borderWidth: 3,
        minItemHeight: 15,
        minItemWidth: 10,
        headerHeight: 20,
        dragProxyWidth: 300,
        dragProxyHeight: 200
    },
};

var myLayout = new GoldenLayout(config);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={darkTheme}>
        <EditorMenu focusCallback={(f) => hasMenuFocus = f}/>
    </ThemeProvider>);


myLayout.registerComponent('edit', function (container, componentState) {
    const scenePanelReact = ReactDOM.createRoot(container.element);
    scenePanelReact.render(<React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <EditorSceneView/>
        </ThemeProvider>
    </React.StrictMode>);
});

myLayout.registerComponent('play', function (container, componentState) {
    const scenePanelReact = ReactDOM.createRoot(container.element);
    scenePanelReact.render(<React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <PlaySceneView/>
        </ThemeProvider>
    </React.StrictMode>);
});

myLayout.registerComponent('scene', function (container, componentState) {
    const scenePanelReact = ReactDOM.createRoot(container.element);
    scenePanelReact.render(<React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <ScenePanel createElementCallback={store.createElement}/>
        </ThemeProvider>
    </React.StrictMode>);
});

myLayout.registerComponent('metadata', function (container, componentState) {
    const scenePanelReact = ReactDOM.createRoot(container.element);
    scenePanelReact.render(<React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <MetadataPanel/>
        </ThemeProvider>
    </React.StrictMode>);
});

myLayout.registerComponent('inspector', function (container, componentState) {
    const elementPanelReact = ReactDOM.createRoot(container.element);
    elementPanelReact.render(<ThemeProvider theme={darkTheme}>
        <ElementPanel focusCallback={(f) => hasElementInspectorFocus = f}/>
    </ThemeProvider>);

});

myLayout.registerComponent('mml', function (container, componentState) {
    const htmlPanel = container.element;
    const root = ReactDOM.createRoot(htmlPanel);
    root.render(<HTMLEditor/>)
});

myLayout.init();

setTimeout(() => {
    document.getElementsByClassName("lm_root")[0].style.margin = "3px";
}, 200);

const deselect = () => {
    store.setSelectedElement(null);
}

let isShift = false;
let isControl = false;

let hasElementInspectorFocus = false;
let hasMenuFocus = false;
let hasScenePanelFocus = false;

window.addEventListener("keydown", (event) => {

    // hack to accept forwarded keyboard events from iframes
    const keyCode = event.keyCode || event.detail.keyCode;

    if (hasElementInspectorFocus) {
        return;
    }

    switch (keyCode) {

        case 16:
            // modal.showModal();
            isShift = true;
            break;

        case 17: // Control
            isControl = true;
            break;

        case 68: // D
            if (isControl) {
                store.cloneSelectedElement();
                event.preventDefault();
            }
            break;

        case 8:

            if (store.getSelectedElement()) {
                store.getSelectedElement().remove();
                deselect();
            }

            break;

        case 27: // Esc
            deselect();

            break;
    }
});

window.addEventListener("keyup", (event) => {

    // hack to accept forwarded keyboard events from iframes
    const keyCode = event.keyCode || event.detail.keyCode;

    if (hasElementInspectorFocus) {
        return;
    }

    switch (keyCode) {
        case 17: // Control
            isControl = false;
            break;

        case 16: // Shift
            isShift = false;
            break;
    }
});


window.addEventListener("dragover", (e) => {
    e.preventDefault();
});

window.addEventListener("drop", (ev) => {
    ev.preventDefault();


    if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...ev.dataTransfer.items].forEach((item, i) => {
            // If dropped items aren't files, reject them
            if (item.kind === "file") {
                const file = item.getAsFile();

                const foo = async () => {
                    const result = await uploadFileToIpfs(file);

                    const hash = result.Hash;
                    const name = result.Name;
                    const url = "https://mml.infura-ipfs.io/ipfs/" + hash;
                    console.log("Uploaded to", url);

                    if (name.endsWith(".glb")) {
                        const element = store.createElement("m-model", [{
                            name: "src", value: url
                        }]);

                    } else if (name.endsWith(".png") || name.endsWith(".jpg") || name.endsWith(".jpeg")) {
                        const element = store.createElement("m-image", [{
                            name: "src", value: url
                        }, {
                            name: "width", value: 4
                        }, {
                            name: "height", value: 4
                        }]);

                    } else if (name.endsWith(".mp3") || name.endsWith(".wav")) {
                        const element = store.createElement("m-audio", [{
                            name: "src", value: url
                        }]);
                    }
                };

                foo();
            }
        });
    }
});

export async function downloadMML(url) {
    let foo = await fetch(url);
    let text = await foo.text();
    const parser = new DOMParser();
    const dom = parser.parseFromString(text, "text/html");
    const body = dom.body;

    const scrub = (element) => {
        if (element.tagName !== "body" && !element.tagName.startsWith("m-")) {
            element.remove();
        } else {
            [...element.children].forEach(scrub);
        }
    };

    scrub(body);

    // document.getElementById("view").innerHTML = "";

    const group = store.createElement("m-group");

    [...body.children].forEach((element) => group.appendChild(element));

}

