import * as React from "react";
import * as store from "../store";
import {removeSelectedSceneElement} from "../store";
import {DiffDOM} from "diff-dom";

const parser = new DOMParser();

const strippedViewPage = parser.parseFromString('<div id="view"></div>', 'text/html');
export const strippedView = strippedViewPage.getElementById("view");

const lastViewPage = parser.parseFromString('<div id="view"></div>', 'text/html');
export const lastView = lastViewPage.getElementById("view");

// function to recursively remove script elements from a DOM tree
function removeScripts(node) {
    if (node.nodeName === "SCRIPT") {
        // set text to empty string
        node.innerText = "";
    } else {
        for (let i = 0; i < node.childNodes.length; i++) {
            removeScripts(node.childNodes[i]);
        }
    }
}

export default function EditorSceneView() {
    const scene = store.useScene();
    const path = store.useSelectedPath();
    const ref = React.useRef(null);

    React.useEffect(() => {
        loadContent(false);
    });

    function loadContent(newPage) {
        if (ref.current && ref.current.contentDocument && scene && ref.current.contentDocument.getElementById("view")) {

            store.setScreenshotFunction(ref.current.contentWindow.screenshot);

            if(newPage) {
                ref.current.contentDocument.getElementById("view").innerHTML = lastView.innerHTML;
            }

            const dd = new DiffDOM({
                // prevent removal of attributes
                preDiffApply: function (info) {
                    if(info.diff.route.length === 0) {
                        return true;
                    }
                }
            });

            strippedView.innerHTML = scene.innerHTML;
            removeScripts(strippedView);

            const sceneA = strippedView;
            const sceneB = lastView;

            const diff = dd.diff(sceneB, sceneA);

            dd.apply(sceneB, JSON.parse(JSON.stringify(diff)));

            dd.apply(ref.current.contentDocument.getElementById("view"), JSON.parse(JSON.stringify(diff)));

            if (path) {
                ref.current.contentWindow.select(path);
            } else {
                ref.current.contentWindow.deselect();
            }
            ref.current.contentWindow.selectCallback = store.setSelectedPath;
            ref.current.contentWindow.transformCallback = ([x, y, z, rx, ry, rz, sx, sy, sz]) => {
                if (store.getSelectedElement()) {
                    x ? store.updateSelectedSceneElement("x", x) : store.removeSelectedSceneElement("x");
                    y ? store.updateSelectedSceneElement("y", y) : store.removeSelectedSceneElement("y");
                    z ? store.updateSelectedSceneElement("z", z) : store.removeSelectedSceneElement("z");

                    rx ? store.updateSelectedSceneElement("rx", rx) : store.removeSelectedSceneElement("rx");
                    ry ? store.updateSelectedSceneElement("ry", ry) : store.removeSelectedSceneElement("ry");
                    rz ? store.updateSelectedSceneElement("rz", rz) : store.removeSelectedSceneElement("rz");

                    sx ? store.updateSelectedSceneElement("sx", sx) : store.removeSelectedSceneElement("sx");
                    sy ? store.updateSelectedSceneElement("sy", sy) : store.removeSelectedSceneElement("sy");
                    sz ? store.updateSelectedSceneElement("sz", sz) : store.removeSelectedSceneElement("sz");
                }
            }
        }
    }

    const content = `
    <html>
    <head>
        <script src="index.js"></script>
    </head>
    <body>
<!--    <m-light color="#FFFFFF" x="2" y="5" z="3" intensity="0.4" enabled="true" type="ambient"></m-light>-->
<!--    <m-light color="#FFFFFF" x="2" y="5" z="3" ry="10" intensity="0.3" enabled="true" type="directional"></m-light>-->
    <div id="view"> </div>
    <script>
        window.addEventListener("keydown", (event) => {
            
            // event.preventDefault();
            
            parent.dispatchEvent(new CustomEvent("keydown", {
                detail : {
                    keyCode : event.keyCode
                }
            }));
        });
            
        window.addEventListener("keyup", (event) => {
            
            // event.preventDefault();
            
            parent.dispatchEvent(new CustomEvent("keyup", {
                detail : {
                    keyCode : event.keyCode
                }
            }));
        });
    </script>
    </body>
    </html>
    `


    return <iframe style={{
        width: "100%", height: "100%", border: "0"
    }} srcDoc={content} ref={ref} onLoad={() => loadContent(true)}/>
}