import {Menu, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as store from "../store";
import {descriptionMap, emojiMap} from "../utils";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DndApp from "./tree/DndApp";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import styles from "./tree/CustomNode.module.css";

export default function ScenePanel({createElementCallback}) {

    const selectedElement = store.useSelectedElement();
    const scene = store.useScene();

    return <Paper
        sx={{p: 1, backgroundColor: "none"}}>
        <DndApp/>
        {/*<SceneTree root={scene} selectedElement={selectedElement}/>*/}
        <AddButton createElementCallback={createElementCallback}/>
    </Paper>
}

// function SceneTree({root, selectedElement}) {
//     let id = 0;
//     let idToElement = {};
//
//     let selectedNodes = [];
//     let expandedNodes = [];
//
//     const renderTree2 = (element) => {
//         const tag = element.tagName;
//         const emoji = emojiMap[tag];
//
//         const nodeId = id.toString();
//         idToElement[nodeId] = element;
//         if(element === selectedElement) {
//             selectedNodes.push(nodeId);
//         }
//         id += 1;
//
//         let children = [];
//         if(element.tagName.toLowerCase() !== "m-frame") {
//             children = [...element.children].map(child => renderTree2(child));
//         }
//
//         return <TreeItem key={nodeId} nodeId={nodeId} label={emoji + " -- " + tag.toLowerCase()} onClick={() => store.setSelectedElement(element)}>
//             {children}
//         </TreeItem>
//     }
//
//     return (
//         <TreeView
//             defaultCollapseIcon={<ExpandMoreIcon />}
//             defaultExpanded={['0']}
//             selected={selectedNodes}
//             defaultExpandIcon={<ChevronRightIcon />}
//             sx={{ width: "90%"}}
//         >
//             {renderTree2(root)}
//         </TreeView>
//     );
// }


function AddButton({createElementCallback}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    let elements = [];
    for (var i in emojiMap) {
        const element = i;
        const emoji = emojiMap[i];
        elements.push([element, emoji, descriptionMap[i] || ""]);
    }

    const menus = elements.map(element => <MenuItem dense
                                                    key={element[0]}
                                                    onClick={() => createElementCallback(element[0])}>
        <div className={styles.labelGridItem}>
            <Typography variant="subtitle2">{`${element[1] + " " + element[0].toLowerCase()}`}</Typography>
        </div>
        <div className={styles.labelGridItem}>
            <Typography variant="caption" sx={{color: "#AAAAAA"}}>{element[2]}</Typography>
        </div>

    </MenuItem>)

    return (
        <div>
            <Button sx={{mr: 1, mb: 0.5, mt: 2}}
                    variant="outlined"
                    onClick={handleClick}
            >
                +
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                // sx={{background: "#222222DD", backdropFilter: "blur(10px"}}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menus}
            </Menu>
        </div>
    );
}