import {Box, Button, Paper, Stack, TextField, Typography} from "@mui/material";
import EditorMenu from "./EditorMenu";
import * as React from "react";
import { ColorPicker } from 'mui-color';
import {descriptionMap, emojiMap} from "../utils";
import * as store from "../store";

export default function ElementPanel({focusCallback}) {

    const element = store.useSelectedElement();

    if (!element) {
        return null;
    }

    const attributePanels = element.getAttributeNames().
    filter(a => a !== "x" && a !== "y" && a !== "z" &&
        a !== "rx" && a !== "ry" && a !== "rz" &&
        a !== "sx" && a !== "sy" && a !== "sz" &&
        a !== "width" && a !== "height" && a !== "color" && a !== "id")
        .map(attribute => <AttributePanel key={attribute} attribute={attribute} element={element} />);


    let elements = [];

    const emoji = emojiMap[element.tagName] || "🔴";

    let key = 0;

    elements.push(<Typography key={key++} variant={"h6"}>{emoji} {element.tagName.toLowerCase()}</Typography>);


    if(descriptionMap[element.tagName]) {
        elements.push(<Typography key={key++} variant={"subtitle2"} sx={{color: "#AAAAAA"}}>{descriptionMap[element.tagName]}</Typography>);
    }

    elements.push(<AttributePanel key={key++} element={element} attribute={"id"}/>);

    elements.push(<Stack key={key++} spacing={1} direction={"row"}>
        <AttributePanel element={element} attribute={"x"}/>
        <AttributePanel element={element} attribute={"y"}/>
        <AttributePanel element={element} attribute={"z"}/>
    </Stack>);

    elements.push(<Stack key={key++} spacing={1} direction={"row"}>
        <AttributePanel element={element} attribute={"rx"}/>
        <AttributePanel element={element} attribute={"ry"}/>
        <AttributePanel element={element} attribute={"rz"}/>
    </Stack>);

    elements.push(<Stack key={key++} spacing={1} direction={"row"}>
        <AttributePanel element={element} attribute={"sx"}/>
        <AttributePanel element={element} attribute={"sy"}/>
        <AttributePanel element={element} attribute={"sz"}/>
    </Stack>);

    elements.push(<AttributePanel key={key++} element={element} attribute={"color"}/>);

    if(element.tagName === "M-LABEL" || element.tagName === "M-PLANE" || element.tagName === "M-IMAGE") {
        elements.push(<Stack key={key++} spacing={1} direction={"row"}>
            <AttributePanel element={element} attribute={"width"}/>
            <AttributePanel element={element} attribute={"height"}/>
        </Stack>);
    }

    return <Paper onFocus={() => focusCallback(true)} onBlur={() => focusCallback(false)}   sx={{ p:1 }}>
        <Stack spacing={1}>
            {elements}
            {attributePanels}
            <NewAttributePanel element={element}/>

        </Stack>
    </Paper>
}

function AttributePanel({attribute}) {
    const element = store.useSelectedElement();
    const value = element.getAttribute(attribute) || "";

    const change = (newValue) => {
        store.updateSelectedSceneElement(attribute, newValue)
        // store.getSelectedElement().setAttribute(attribute, value);
        // remoteConnection.emitSetAttribute(store.getSelectedElement(), attribute, newValue);
    }

    if(attribute === "color") {
        return <Box sx={{width: 100}}><ColorPicker size="small" defaultValue="white" value={value}  onChange={e => change(e.css.backgroundColor)}/></Box>
    } else {
        return <TextField size="small" label={attribute} variant="filled" value={value} onChange={e => change(e.target.value)}/>
    }
}

function NewAttributePanel({element}) {

    const [value, setValue] = React.useState("");

    const addAttribute = () => {
        console.log(value)
        element.setAttribute(value, "undefined");
        setValue("");
    }

    return <div>
        <Stack spacing={1} direction={"row"}>
            <TextField id="attributeToAdd" size="small" label={"attribute"} variant="outlined" value={value} onChange={e => setValue(e.target.value)}/>
            <Button variant="outlined" onClick={addAttribute}>+</Button>
        </Stack>
    </div>
}