import PubSub from 'pubsub-js'
import * as React from 'react'
import {uniqueNamesGenerator, Config, adjectives, colors, animals} from 'unique-names-generator';
import {SERVER_HTTP} from "./server";

let selectedPath = null;

const parser = new DOMParser();
const viewPage = parser.parseFromString('<div id="view"></div>', 'text/html');
export const view = viewPage.getElementById("view");

export function getSelectedElement() {
    return selectedPath ? pathToElement(selectedPath, view) : null;
}

export function getSelectedPath() {
    return selectedPath;
}

export function setSelectedElement(element) {
    setSelectedPath(element ? elementToPath(element, view) : null);
}

export function setSelectedPath(path) {
    selectedPath = path;
    PubSub.publish("selected", "modified");
}

export function updateSelectedSceneElement(attribute, value) {
    getSelectedElement().setAttribute(attribute, value);
    PubSub.publish("scene-update", {
        element: getSelectedElement(), type: "update", name: attribute, value: value,
    });
}

export function removeSelectedSceneElement(attribute) {
    getSelectedElement().removeAttribute(attribute);
    PubSub.publish("scene-update", {
        element: getSelectedElement(), type: "remove", name: attribute,
    });
}

export function createElement(name, attributes = []) {
    const element = document.createElement(name.toLowerCase());

    attributes.forEach(attr => {
        element.setAttribute(attr.name, attr.value);
    });

    const root = view;
    root.appendChild(element);

    PubSub.publish("scene-update", {
        element: view, type: "create", name, attributes
    });
    return element;
}

export function updateSceneElement(path, updatedAttributes, clearedAttributes) {
    pathToElement(path).setAttribute();
}

export function addSceneElement(path, tagName, attributes) {
    PubSub.publish("scene", "modified");
}

export function removeSceneElement(path) {
    PubSub.publish("scene", "modified");
}

export function editScene(edit) {
    PubSub.publish("scene", "modified");
}

export function cloneSelectedElement() {
    console.log("cloning");
    const cloned = getSelectedElement().cloneNode(true);
    getSelectedElement().parentNode.appendChild(cloned);
}

export function elementToPath(element, root) {
    const path = [];
    let currentElement = element;
    while (currentElement !== root) {
        const parentChildren = [...currentElement.parentElement.children];
        path.push(parentChildren.indexOf(currentElement));
        currentElement = currentElement.parentElement;
    }
    return path.reverse();
};

export function pathToElement(path, root) {
    let currentElement = root;
    path.forEach((i) => {
        currentElement = currentElement.children[i];
    });
    return currentElement;
};

const observer = new MutationObserver(() => {
    PubSub.publish("scene", "modified");
    localStorage.setItem("editor-html", view.innerHTML);
});

observer.observe(view, {
    subtree: true, attributes: true, characterData: true, childList: true,
});

export function useSelectedPath() {
    let [path, setPath] = React.useState(selectedPath);

    React.useEffect(() => {
        const handle = PubSub.subscribe("selected", () => {
            setPath(selectedPath);
        });
        return () => PubSub.unsubscribe(handle);
    });

    return path;
}

export function getScene() {
    return view.innerHTML;
}

export function useScene() {
    let [scene, setScene] = React.useState(view);
    let [epoch, setEpoch] = React.useState(0);

    React.useEffect(() => {
        const handle = PubSub.subscribe("scene", () => {
            setScene(view);
            setEpoch(epoch + 1);
        });
        return () => PubSub.unsubscribe(handle);
    });

    return scene;
}

export function useSelectedElement() {
    let [element, setElement] = React.useState(getSelectedElement());
    let [epoch, setEpoch] = React.useState(0);

    React.useEffect(() => {
        const handle = PubSub.subscribe("selected", () => {
            setElement(getSelectedElement());
        });
        return () => PubSub.unsubscribe(handle);
    });

    React.useEffect(() => {
        const handle = PubSub.subscribe("scene", () => {
            setElement(getSelectedElement());
            setEpoch(epoch + 1);
        });
        return () => PubSub.unsubscribe(handle);
    });

    return element;
}


let sceneName = "";

export async function setSceneName(newName) {
    sceneName = newName;
    PubSub.publish("sceneName", "modified");
    localStorage.setItem("editor-scene-name", newName);
    window.history.pushState({}, '', sceneName);

    try {
        const result = await fetch(`${SERVER_HTTP}/objects/${newName}`);
        const html = await result.text();
        view.innerHTML = html;
        sceneNeedsUpload = false;
    } catch (e) {
        console.log("ERROR FETCHING SCENE");
    }

    if (view.innerHTML === "") {
        view.innerHTML = `
        <m-model src="https://mml.infura-ipfs.io/ipfs/QmR65KJAkDzriDx7YMQVBHvK5ENQ2MAihFzPALx5hcHmxQ" rx="180" rz="180" sx="2" sy="2" sz="2" y="-0.2636601580913247" ry="-45.00000000000001" z="1" x="-2"></m-model>
<m-light type="directional" y="10" rx="45" z="6.9036280404576615"></m-light>
<m-model src="https://mml.infura-ipfs.io/ipfs/QmQKbFtcAgxMU3UHr89d84pAEd4TpQSSgVL8Qxzcr1yU12" z="-3.594226020252936"></m-model>
<m-cube x="2.8920255458427" y="0.8059838554866632" ry="44.25333853287441" z="1.4871049209174396" sx="1.7759670880835519" sy="1.7759670880835519" sz="1.7759670880835519" color="#111ED6"></m-cube>
        `
    }
}

export function getSceneName() {
    return sceneName;
}

export function useSceneName() {
    let [localSceneName, setLocalSceneName] = React.useState(sceneName);

    React.useEffect(() => {
        const handle = PubSub.subscribe("sceneName", () => {
            setLocalSceneName(getSceneName());
        });
        return () => PubSub.unsubscribe(handle);
    });

    return localSceneName;
}


let sceneNeedsUpload = false;

PubSub.subscribe("scene", () => {
    sceneNeedsUpload = true;
});

setInterval(() => {
    if (sceneNeedsUpload) {
        sceneNeedsUpload = false;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        var raw = view.innerHTML;

        var requestOptions = {
            method: 'PUT', headers: myHeaders, body: raw,
        };

        fetch(`${SERVER_HTTP}/objects/${getSceneName()}`, requestOptions);
        console.log("Pushed new scene update for ", getSceneName());
    }
}, 500);

view.innerHTML = localStorage.getItem("editor-html");

const randomName = uniqueNamesGenerator({dictionaries: [adjectives, colors, animals], separator: '-'});

setSceneName(window.location.pathname.substring(1) || randomName);


let screenshotFunction;

export function setScreenshotFunction(func) {
    screenshotFunction = func;
}

export function takeScreenshot() {
    return screenshotFunction();
}

