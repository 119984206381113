import * as React from "react";
import * as store from "../store";
import {SERVER_WS} from "../server";

export default function PlaySceneView() {
    const sceneName = store.useSceneName();

    const content = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="utf-8">
        <title>mml-editor</title>
    </head>
    <body>
        <script src="https://metaweb.host/meta-space.js"></script>
        <script src="https://avatar.metaweb.tools/basic-avatar-controller.js"></script>
        <script src="https://construct.metaweb.tools/construct-env.js"></script>
        <script src="https://sunny-day.metaweb.tools/sunny-day.js"></script>
        <script src="https://metaweb.directory/launcher.js"></script>
<!--        <script src="https://metaweb.directory/launcher.js"></script>-->
        <script src="https://dom.metaweb.tools/index.js?url=${SERVER_WS}/objects/${sceneName}"></script>
    </body>
    </html>
    `
    return <iframe style={{
        width: "100%",
        height: "100%",
        border: "0",
    }} srcDoc={content}/>
}