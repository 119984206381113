import React from "react";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import styles from "./CustomNode.module.css";

export const CustomNode = (props) => {
    const { droppable, data } = props.node;
    const indent = props.depth * 24;

    const handleToggle = (e) => {
        e.stopPropagation();
        props.onToggle(props.node.id);
    };

    const handleSelect = () => props.onSelect(props.node);

    return (
        <div
            className={`tree-node ${styles.root} ${
                props.isSelected ? styles.isSelected : ""
            }`}
            style={{ paddingInlineStart: indent }}
            onClick={handleSelect}
        >
            <div
                className={`${styles.expandIconWrapper} ${
                    props.isOpen ? styles.isOpen : ""
                }`}
            >
                {props.node.hasChildren && (
                    <div onClick={handleToggle}>
                        <ArrowRightIcon />
                    </div>
                )}
            </div>
            <div>
                {props.node.emoji}
            </div>
            <div className={styles.labelGridItem}>
                <Typography variant="subtitle2" >{`${props.node.text}`}</Typography>
            </div>
            <div className={styles.labelGridItem}>
                <Typography variant="caption" sx={{color: "#AAAAAA"}}>{`${props.node.element.id}`}</Typography>
            </div>

        </div>
    );
};
