import React, {useState} from "react";
import {DndProvider} from "react-dnd";
import {ThemeProvider, CssBaseline, Paper} from "@mui/material";
import {
    Tree,
    MultiBackend,
    getBackendOptions
} from "@minoru/react-dnd-treeview";
import {CustomNode} from "./CustomNode";
import {theme} from "./theme";
import {Placeholder} from "./Placeholder";
import styles from "./App.module.css";
import SampleData from "./sample_data.json";
import {CustomDragPreview} from "./CustomDragPreview";
import * as store from "../../store";
import {emojiMap} from "../../utils";
import TreeItem from "@mui/lab/TreeItem";

function DndApp() {


    const selectedElement = store.useSelectedElement();
    const scene = store.useScene();
    // const [selectedNode, setSelectedNode] = useState(null);
    const handleSelect = (node) => store.setSelectedElement(node.element);

    let id = 1;
    let idToElement = {};
    idToElement[0] = scene;

    const sceneToTreeData = (element, parentNodeId = 0) => {

        if(element.tagName.toLowerCase() === "script") {
            return [];
        }

        const tag = element.tagName;
        const emoji = emojiMap[tag];

        const nodeId = id;
        idToElement[id] = element;
        id += 1;

        let children = [];
        if(element.tagName.toLowerCase() !== "m-frame") {
            children = [...element.children].map(child => sceneToTreeData(child, nodeId));
        }

        return [{
            id : nodeId,
            element : element,
            parent : parentNodeId,
            droppable : true,
            text : tag.toLowerCase(),
            emoji : emoji,
            hasChildren : children.length > 0
        }].concat(...children);
    }

    const treeData = [...scene.children].flatMap(element => sceneToTreeData(element));
    
    const handleDrop = (newTree) => {
        store.setSelectedElement(null);
        newTree.forEach(node => {
            if(idToElement[node.parent] !== node.element.parentElement) {
                idToElement[node.parent].appendChild(node.element);
            }
        })
    }

    // const [treeData, setTreeData] = useState(SampleData);

    return (
        <ThemeProvider theme={theme.darkTheme}>
            <CssBaseline/>
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <div className={styles.app}>
                    <Tree
                        tree={treeData}
                        rootId={0}
                        render={(node, {depth, isOpen, onToggle}) => (
                            <CustomNode
                                node={node}
                                depth={depth}
                                isOpen={isOpen}
                                onToggle={onToggle}
                                isSelected={node.element === selectedElement}
                                onSelect={handleSelect}
                            />
                        )}
                        dragPreviewRender={(monitorProps) => (
                            <CustomDragPreview monitorProps={monitorProps}/>
                        )}
                        onDrop={handleDrop}
                        classes={{
                            root: styles.treeRoot,
                            draggingSource: styles.draggingSource,
                            placeholder: styles.placeholderContainer
                        }}
                        sort={false}
                        insertDroppableFirst={false}
                        canDrop={(tree, {dragSource, dropTargetId, dropTarget}) => {
                            if (dragSource?.parent === dropTargetId) {
                                return true;
                            }
                        }}
                        dropTargetOffset={5}
                        placeholderRender={(node, {depth}) => (
                            <Placeholder node={node} depth={depth}/>
                        )}
                    />
                </div>
            </DndProvider>
        </ThemeProvider>
    );
}

export default DndApp;
