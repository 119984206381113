import {Paper, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import * as store from "../store";
import * as React from "react";
import {uploadBlobToIpfs} from "../ipfs";
import * as ifps from "../ipfs";
import {descriptionMap} from "../utils";
import {SERVER_WS} from "../server";
import {CopyToClipboard} from "./CopyToClipboard";


export default function MetadataPanel() {

    return <>
        <ScreenshotTaker/>
        <WebsocketURLPanel/>
    </>
}

function WebsocketURLPanel() {
    const websocketUrl = `${SERVER_WS}/objects/${store.getSceneName()}`;

    return <Paper sx={{p: 1}}>
        <TextField size="small" label={"name"} disabled={true} variant="filled" value={websocketUrl} onChange={e => {}}/>
        <CopyToClipboard>
            {({ copy }) => (
              <Button  variant={"outlined"} onClick={() => copy(websocketUrl)}>
                  Copy to Clipboard
              </Button>
            )}
        </CopyToClipboard>
    </Paper>;
}

function ScreenshotTaker() {

    // react storeage of current image data
    const [imageData, setImageData] = React.useState(null);


    const takeScreenshot = async() => {

        const imageData =store.takeScreenshot();
        var data = atob( imageData.substring( "data:image/jpeg;base64,".length ) ),
            asArray = new Uint8Array(data.length);
        for( var i = 0, len = data.length; i < len; ++i ) {
            asArray[i] = data.charCodeAt(i);
        }
        var blob = new Blob( [ asArray.buffer ], {type: "image/jpeg"} );

        const result = await ifps.uploadBlobToIpfs(blob)
        const hash = result.Hash;
        const name = result.Name;
        const url = "https://mml.infura-ipfs.io/ipfs/" + hash;

        setImageData(url);

    };

    return <Paper sx={{p:1}}>
        {imageData && <img width={"100%"}  src={imageData}/>}
        <TextField size="small" label={"name"} disabled={true} variant="filled" value={store.getSceneName()} onChange={e => {}}/>
        <TextField size="small" label={"image"} variant="filled" value={imageData} onChange={e => {setImageData(e.target.value)}}/>
        <Button  variant={"outlined"} onClick={takeScreenshot}>Take Screenshot
        </Button>
    </Paper>
}