let httpURl, wsURL;

const production = true;

if (production) {
  httpURl = 'https://mml-editor-server-ogqo7ubdxa-nw.a.run.app';
  wsURL = 'wss://mml-editor-server-ogqo7ubdxa-nw.a.run.app';
} else {
  httpURl = 'http://localhost:8081';
  wsURL = 'ws://localhost:8081';
}

export const SERVER_HTTP = httpURl;
export const SERVER_WS = wsURL;