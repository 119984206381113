

export let emojiMap = {
    "M-LIGHT" : "💡",
    "M-MODEL" : "🗿",
    "M-BUTTON" : "🖱️",
    "M-PLANE" : "⬜",
    "M-LABEL" : "🏷️",
    "DIV" : "🎬",
    "M-IMAGE" : "🖼️",
    "M-GROUP" : "📦",
    "M-AUDIO" : "🔉",
    "M-CUBE" : "🟦",
    "M-FRAME" : "📥",
    "M-PROMPT" : "❔"
}

export let descriptionMap = {
    "M-LIGHT" : "Configurable light source",
    "M-MODEL" : "GLB model from a URL",
    "M-BUTTON" : "Clickable button",
    "M-PLANE" : "Flat plane primitive",
    "M-LABEL" : "Text label",
    "M-IMAGE" : "Image from a URL",
    "M-GROUP" : "Group of objects",
    "M-AUDIO" : "Audio from a URL",
    "M-CUBE" : "Cube primitive",
    "M-FRAME" : "Stream another nested MML object from a URL",
    "M-PROMPT" : "Prompt for user input"
}